import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { WORKSPACE_PREFIX_PATH } from 'configs/AppConfig';

const Dashboards = lazy(() => import('./dashboards'));
const Apps = lazy(() => import('./apps'));

const WorkspaceViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${WORKSPACE_PREFIX_PATH}/dashboards`}
          component={Dashboards}
        />
        <Route path={`${WORKSPACE_PREFIX_PATH}/apps`} component={Apps} />
        <Redirect
          from={`${WORKSPACE_PREFIX_PATH}`}
          to={`${WORKSPACE_PREFIX_PATH}/apps/project`}
        />
      </Switch>
    </Suspense>
  );
};

export default WorkspaceViews;
