import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  addTaskInList,
  updateTaskInList,
} from 'redux/actions/manage-project/list';
import {
  addTaskToListSprint,
  deleteTaskInListSprintSuccess,
} from 'redux/actions/manage-project/listSprint';
import {
  createTaskInSprintSuccess,
  deleteTaskInSprintSuccess,
  updateTaskInSprintSuccess,
} from 'redux/actions/manage-project/sprint';
import {
  createdTaskSuccess,
  deleteCurrentTaskSuccess,
  deleteTaskSuccess,
  dragTaskSuccess,
  loadTaskSuccess,
  setDescriptionSuccess,
  setMembersSuccess,
  setStoryPointSuccess,
  showTaskMessage,
  updateTaskSuccess,
  loadAllTaskSuccess,
} from 'redux/actions/manage-project/task.action';
import {
  CREATE_TASK,
  DELETE_CURRENT_TASK,
  DELETE_TASK,
  DRAG_TASK,
  LOAD_TASK_BY_LIST,
  SET_LABELS,
  SET_MEMBERS,
  UPDATE_TASK,
  SET_DESCRIPTION,
  SET_STORYPOINT,
  LOAD_ALL_TASK,
} from 'redux/constants/manage-project/task.type';
import {
  created,
  loaded,
  deleted,
  loadByList,
  setLabelsReuqest,
  setMembersReuqest,
  setDescriptionReuqest,
  setStoryPointRequest,
  updated,
} from 'services/TaskService';
import {
  createComment,
} from 'redux/actions/manage-project/comment';

export function* byList() {
  yield takeEvery(LOAD_TASK_BY_LIST, function* ({ payload }) {
    const { listID } = payload;
    try {
      const response = yield call(loadByList, listID);
      if (response.data.message) {
        yield put(showTaskMessage(response.data.message));
      } else {
        yield put(loadTaskSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showTaskMessage(err.response ? err.response.data : err));
    }
  });
}

export function* allTask() {
  yield takeEvery(LOAD_ALL_TASK, function* () {
    try {
      const response = yield call(loaded);
      if (response.data.message) {
        yield put(showTaskMessage(response.data.message));
      } else {
        yield put(loadAllTaskSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showTaskMessage(err.response ? err.response.data : err));
    }
  });
}

export function* create() {
  yield takeEvery(CREATE_TASK, function* ({ payload }) {
    const { task, sprintId } = payload;
    try {
      const response = yield call(created, task, sprintId);
      if (response.data.message) {
        yield put(showTaskMessage(response.data.message));
      } else {
        if (sprintId && sprintId != undefined) {
          yield put(
            createTaskInSprintSuccess({ task: response.data.data, sprintId })
          );
        }
        if (task.listSprintId && task.listSprintId != undefined) {
          yield put(addTaskToListSprint(response.data.data));
        }
        if (task.listId && task.listId !== undefined)
          yield put(addTaskInList(response.data.data));
        yield put(
          createdTaskSuccess({
            task: response.data.data,
            idList: response.data.data.listId,
          })
        );
        yield put(
          createComment({
            content: 'Creation du ticket',
            extension: '',
            taskId: response.data.data._id,
            type: 'TEXT',
            user: response.data.data.authorId,
            isNotif: false,
          })
        );
      }
    } catch (err) {
      yield put(showTaskMessage(err.response ? err.response.data : err));
    }
  });
}

export function* update() {
  yield takeEvery(UPDATE_TASK, function* ({ payload }) {
    const { taskID, task, sprintId } = payload;
    try {
      const response = yield call(updated, taskID, task);
      if (response.data.message) {
        yield put(showTaskMessage(response.data.message));
      } else {
        if (sprintId && sprintId !== undefined) {
          yield put(
            updateTaskInSprintSuccess({ task: response.data.data, sprintId })
          );
        }
        if (task.listId && task.listId !== undefined) {
          yield put(updateTaskInList(response.data.data));
        }
        if (!task.listId || task.listId !== undefined) {
          yield put(updateTaskInList(task));
        }
        yield put(updateTaskSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showTaskMessage(err.response ? err.response.data : err));
    }
  });
}

export function* setMembersSaga() {
  yield takeEvery(SET_MEMBERS, function* ({ payload }) {
    const { taskID, members, sprintId } = payload;
    try {
      const response = yield call(setMembersReuqest, taskID, members);

      if (sprintId && sprintId !== undefined) {
        yield put(
          updateTaskInSprintSuccess({ task: response.data.data, sprintId })
        );
      }
      if (
        response.data.data.listId &&
        response.data.data.listId !== undefined
      ) {
        yield put(updateTaskInList(response.data.data));
      }
      yield put(setMembersSuccess(response.data.data));
    } catch (err) {
      yield put(showTaskMessage(err.response ? err.response.data : err));
    }
  });
}

export function* setLabelsSaga() {
  yield takeEvery(SET_LABELS, function* ({ payload }) {
    const { taskID, labels, sprintId } = payload;
    try {
      const response = yield call(setLabelsReuqest, taskID, labels);

      if (sprintId && sprintId !== undefined) {
        yield put(
          updateTaskInSprintSuccess({ task: response.data.data, sprintId })
        );
      }
      if (
        response.data.data.listId &&
        response.data.data.listId !== undefined
      ) {
        yield put(updateTaskInList(response.data.data));
      }
      yield put(setMembersSuccess(response.data.data));
    } catch (err) {
      yield put(showTaskMessage(err.response ? err.response.data : err));
    }
  });
}

export function* setDescriptionSaga() {
  yield takeEvery(SET_DESCRIPTION, function* ({ payload }) {
    const { taskID, description, sprintId } = payload;
    try {
      const response = yield call(setDescriptionReuqest, taskID, description);
      if (sprintId && sprintId !== undefined) {
        yield put(
          updateTaskInSprintSuccess({ task: response.data.data, sprintId })
        );
      }
      if (
        response.data.data.listId &&
        response.data.data.listId !== undefined
      ) {
        yield put(updateTaskInList(response.data.data));
      }
      yield put(setDescriptionSuccess(response.data.data));
    } catch (err) {
      yield put(showTaskMessage(err.response ? err.response.data : err));
    }
  });
}

export function* setStoryPointSaga() {
  yield takeEvery(SET_STORYPOINT, function* ({ payload }) {
    const { taskID, storyPoint, sprintId } = payload;
    try {
      const response = yield call(setStoryPointRequest, taskID, storyPoint);
      if (sprintId && sprintId !== undefined) {
        yield put(
          updateTaskInSprintSuccess({ task: response.data.data, sprintId })
        );
      }
      if (
        response.data.data.listId &&
        response.data.data.listId !== undefined
      ) {
        yield put(updateTaskInList(response.data.data));
      }
      yield put(setStoryPointSuccess(response.data.data));
    } catch (err) {
      yield put(showTaskMessage(err.response ? err.response.data : err));
    }
  });
}

export function* deleteTask() {
  yield takeEvery(DELETE_TASK, function* ({ payload }) {
    try {
      const response = yield call(deleted, payload.taskID, payload.sprintId);
      if (response.data.message) {
        yield put(showTaskMessage(response.data.message));
      } else {
        if (payload.sprintId) {
          yield put(
            deleteTaskInSprintSuccess({
              task: response.data.data,
              sprintId: payload.sprintId,
            })
          );
        }
        if (response.data.data.listSprintId) {
          yield put(
            deleteTaskInListSprintSuccess({
              task: response.data.data,
              sprintId: payload.sprintId,
            })
          );
        }
        yield put(deleteTaskSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showTaskMessage(err.response ? err.response.data : err));
    }
  });
}

export function* dragTask() {
  yield takeEvery(DRAG_TASK, function* ({ payload }) {
    try {
      yield put(dragTaskSuccess(payload));
    } catch (err) {
      yield put(showTaskMessage('Erreur drag'));
    }
  });
}

export function* deleteCurrentTask() {
  yield takeEvery(DELETE_CURRENT_TASK, function* ({ payload }) {
    try {
      yield put(deleteCurrentTaskSuccess());
    } catch (err) {
      yield put(showTaskMessage('Erreur drag'));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(byList),
    fork(create),
    fork(update),
    fork(deleteTask),
    fork(dragTask),
    fork(deleteCurrentTask),
    fork(setMembersSaga),
    fork(setLabelsSaga),
    fork(setDescriptionSaga),
    fork(setStoryPointSaga),
    fork(allTask),
  ]);
}
