import { APP_PREFIX_PATH, WORKSPACE_PREFIX_PATH } from 'configs/AppConfig';
import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { blankWorkspace } from 'redux/actions/manage-project/project.action';
import { Link } from 'react-router-dom';
import { RouteContext } from 'providers/RouteProvider';

function RoutePath({
  currentWorkspaceData,
  byWorkspaces,
  setLocale,
  closeMobileNav,
}) {
  const { actualRoute } = useContext(RouteContext);
  const [workspaceName, setWorkspaceName] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [inProject, setInProject] = useState(false);
  const dispatch = useDispatch();
  const { wpId, pId } = useParams()
  const currentProject = pId || null;

  useEffect(() => {
    setWorkspaceName(currentWorkspaceData?.name);
  }, [currentWorkspaceData]);

  useEffect(() => {
    setProjectName(
      byWorkspaces?.find((item) => item._id === currentProject)?.name
    );

    if (actualRoute && currentProject && !inProject) {
      setInProject(true);
    } else if ((!actualRoute || !currentProject) && inProject) {
      setInProject(false);
    }
  }, [byWorkspaces, currentProject, actualRoute]);

  if (workspaceName)
    return (
      <div style={{ width: '100%', padding: '12px 12px 0 24px' }}>
        <p
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'uppercase',
          }}
        >
          <Link
            to={WORKSPACE_PREFIX_PATH.replace(':wpId', wpId) + '/dashboards'}
            onClick={() => {
              dispatch(blankWorkspace());
            }}
            className={`blueHoverText ${inProject ? 'menu-dark' : ''}`}
          >
            <span>{workspaceName}</span>
          </Link>
          {inProject && <span>{` / `}</span>}
          {inProject && (
            <Link
              onClick={() => {
                closeMobileNav();
                dispatch(blankWorkspace());
                localStorage.removeItem('__CURRENT_PROJECT__');
              }}
              to={WORKSPACE_PREFIX_PATH.replace(':wpId', wpId) + '/apps/project'}
              className={`blueHoverText ${projectName ? 'menu-dark' : ''}`}
            >
              <span>{setLocale(true, actualRoute.title)}</span>
            </Link>
          )}
          {projectName && <span>{` / `}</span>}
          {projectName && (
            <Link
              to={APP_PREFIX_PATH.replace(':pId', pId) + '/apps/dashboard'}
              className={`blueHoverText text-primary`}
            >
              <span>{projectName}</span>
            </Link>
          )}
        </p>
      </div>
    );
  return null;
}

const mapStateToProps = ({ workspaces, project }) => {
  const { currentWorkspaceData } = workspaces;
  const { byWorkspaces } = project;
  return { currentWorkspaceData, byWorkspaces };
};
const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RoutePath)
);
